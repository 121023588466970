class genericReportPage extends Component {

    static name() {
        return "genericReportPage";
    }

    static componentName() {
        return "genericReportPage";
    }

    data() {
        return {
            report:null
        };
    }

    created() {
        return function () {
            this.changeReportName=this.changeReportName.bind(this);
            this.subscribeEvent('changeReportView',this.changeReportName)
        };
    }

    getMethods() {
        return {
            changeReportName:this.changeReportName
        };
    }

    changeReportName(reportName){
        console.log('Change Report Name',reportName);
        this.report = reportName;
    }



    getTemplate() {
        return `<div class="reportPage"  v-if="report"> 
                  <div :class="classContainer">
                      <div class="row justify-content-center section main">
                          <template v-if="$store.getters.getAppState=='ready'">
                            <div class="col-11">
                                <div class="row justify-content-center">
                                    <div class="col-12 clearfix">
                                         <reportComponent :reportName="report">
                                            <template slot="parameters" scope="reportScope">
                                                <genericReportForm :reportScope="reportScope" :reportName="report"></genericReportForm>
                                            </template>
                                         </reportComponent>
                                    </div>
                                </div>
                            </div>
                          </template>
                          <template v-else>
                            <div class="container center section white" style="min-height: 60%;" >
                               <h3>{{tr('Page loading please wait')}}</h3>
                             </div>
                          </template>
                      </div>
                  </div>
                </div>`;
    }
}

genericReportPage.registerComponent();


/*

Vue.component('genericReportPage',{
    data:function(){
        return {
            report:null
        }
    },
    created:function(){
        this.changeReportName=this.changeReportName.bind(this);
        this.subscribeEvent('changeReportView',this.changeReportName)
    },
    methods:{
        changeReportName(reportName){
            console.log('Change Report Name',reportName);
            this.report=reportName;
        }
    },
    template:`<div class="reportPage"  v-if="report"> 
                  <div class="container-fluid">
                      <div class="row justify-content-center section main">
                          <template v-if="$store.getters.getAppState=='ready'">
                            <div class="col-11">
                                <div class="row justify-content-center">
                                    <div class="col-12 clearfix">
                                         <reportComponent :reportName="report">
                                            <template slot="parameters" scope="reportScope">
                                                <genericReportForm :reportScope="reportScope" :reportName="report"></genericReportForm>
                                            </template>
                                         </reportComponent>
                                    </div>
                                </div>
                            </div>
                          </template>
                          <template v-else>
                            <div class="container center section white" style="min-height: 60%;" >
                               <h3>{{tr('Page loading please wait')}}</h3>
                             </div>
                          </template>
                      </div>
                  </div>
                </div>`
})*/
